// Filename - App.js
 
import React, { useState } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
    useLocation
} from "react-router-dom";
import Home from "./Pages/home";
import About from "./Pages/about";
// import ProductPage from "./Pages/productPage";
import Lookbook from "./Pages/lookbook";
import Navbar from "./Components/Navbar";
import DeliveryAndReturns from "./Pages/deliveryAndReturns";
import PantsProductPage from "./Pages/pantsProductPage";
import PulloverProductPage from "./Pages/pulloverProductPage";
import TshirtProductPage from "./Pages/tshirtProductPage";
import HoodieProductPage from "./Pages/hoodieProductPage";
import PasswordModal from "./Components/PasswordModal"; // Import your modal component
import CombinedPage from "./Pages/combinedPage";
import TestingSnipcart from "./Pages/testingSnipcart";
import TestingSnipcartAPI from "./Pages/testingSnipcartAPI";
import TestingSplitflap from "./Pages/testingSplitflap";
import "./Styles/snipcart.css";

function App() {


//removed password
  return (
    <Router>
        <>
          <Navbar />
          <Routes>
            <Route exact path="/" element={<TestingSplitflap />} />
            <Route path="/about" element={<About />} />
            {/* <Route path="/avery-hoodie" element={<ProductPage />} /> */}
            <Route path="/lyon-pants" element={<PantsProductPage />} />
            <Route path="/como-pullover" element={<PulloverProductPage />} />
            <Route path="/shion-tshirt" element={<TshirtProductPage />} />
            <Route path="/avery-hoodie" element={<HoodieProductPage />} />
            <Route path="/lookbook" element={<Lookbook />} />
            <Route path="/delivery-and-returns" element={<DeliveryAndReturns />} />
            <Route path="/snipcart-test" element={<TestingSnipcart />} />
            <Route path="/snipcart-api" element={<TestingSnipcartAPI />} />
          </Routes>
        </>
          </Router>
  );
}

export default App;