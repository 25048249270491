import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// Define specific character sets for each column type
const CharacterSets = {
  TIME: [' ', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ':'],
  FROM: [' ', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
         'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
  FLIGHT: [' ', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  REMARKS: [' ', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
            'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
};

const Flap = ({ char = ' ' }) => (
  <div className="flap flex-center-all">
    <div className="top">
      <div className="top-flap-queued">
        <span>{char}</span>
      </div>
      <div className="top-flap-visible">
        <span>{char}</span>
      </div>
    </div>
    <div className="bottom">
      <div className="bottom-flap-queued">
        <span>{char}</span>
      </div>
      <div className="bottom-flap-visible">
        <span>{char}</span>
      </div>
    </div>
  </div>
);

const SplitFlapDisplay = ({ word, width = 7, type = 'REMARKS' }) => {
  const flapRef = useRef(null);
  const animationRef = useRef({});
  const timeoutRef = useRef(null);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [isInitialized, setIsInitialized] = useState(false);
  const completedFlapsRef = useRef(0);
  const [initialWord] = useState(Array.isArray(word) ? word[0] : word);
  const elementsCache = useRef({}); // Cache for DOM elements

  const WORDS = Array.isArray(word) ? word : [word];
  const characterSet = CharacterSets[type] || CharacterSets.REMARKS;

  // Cache DOM elements to avoid repeated queries
  const getElementCache = (item, selector) => {
    const itemId = item.dataset.index;
    if (!elementsCache.current[itemId]) {
      elementsCache.current[itemId] = {};
    }
    if (!elementsCache.current[itemId][selector]) {
      elementsCache.current[itemId][selector] = item.querySelector(selector);
    }
    return elementsCache.current[itemId][selector];
  };

  // Cleanup function for timeouts and event listeners
  const cleanup = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    elementsCache.current = {};
    animationRef.current = {};
    completedFlapsRef.current = 0;
  };

  // Move to the next word in the sequence
  const moveToNextWord = () => {
    const nextIndex = (currentWordIndex + 1) % WORDS.length;
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setCurrentWordIndex(nextIndex);
      completedFlapsRef.current = 0;
    }, 50);
  };

  // Batch updates for all flaps using requestAnimationFrame
  const batchUpdateFlaps = (updates) => {
    requestAnimationFrame(() => {
      updates.forEach(({ index, topVisible, topQueued, bottomVisible, bottomQueued }) => {
        const flap = flapRef.current.children[index];
        if (!flap || flap.tagName.toLowerCase() === 'svg') return;

        const topFlapVisible = getElementCache(flap, '.top-flap-visible');
        const topFlapQueued = getElementCache(flap, '.top-flap-queued');
        const bottomFlapVisible = getElementCache(flap, '.bottom-flap-visible');
        const bottomFlapQueued = getElementCache(flap, '.bottom-flap-queued');

        // Update text content
        if (topVisible !== undefined) topFlapVisible.querySelector('span').textContent = topVisible;
        if (topQueued !== undefined) topFlapQueued.querySelector('span').textContent = topQueued;
        if (bottomVisible !== undefined) bottomFlapVisible.querySelector('span').textContent = bottomVisible;
        if (bottomQueued !== undefined) bottomFlapQueued.querySelector('span').textContent = bottomQueued;
      });
    });
  };

  // Function to start or restart flap animation
  const startFlapAnimation = (flapIndex) => {
    const flap = flapRef.current.children[flapIndex];
    if (!flap || flap.tagName.toLowerCase() === 'svg') return;

    const topFlapVisible = getElementCache(flap, '.top-flap-visible');
    const bottomFlapQueued = getElementCache(flap, '.bottom-flap-queued');

    // Remove animation classes to reset state
    topFlapVisible.classList.remove('top-flap-animation');
    bottomFlapQueued.classList.remove('bottom-flap-animation');

    // Force reflow to ensure animation resets
    void topFlapVisible.offsetWidth;
    void bottomFlapQueued.offsetWidth;

    // Add classes back to restart animation in the next frame
    requestAnimationFrame(() => {
      topFlapVisible.classList.add('top-flap-animation');
      bottomFlapQueued.classList.add('bottom-flap-animation');
    });
  };

  // Set up persistent event listeners and manage animations
  useEffect(() => {
    if (!flapRef.current || !isInitialized) return;

    const flapElements = [...flapRef.current.children].filter(item => item.tagName.toLowerCase() !== 'svg');
    const currentChars = flapElements.map(item => {
      return item.querySelector('.bottom-flap-visible span')?.textContent || characterSet[0];
    });
    const targetWord = WORDS[currentWordIndex].padEnd(width, ' ').split('');

    // Clean up previous animations
    cleanup();

    // Initialize animation state for each flap
    flapElements.forEach((flap, index) => {
      flap.dataset.index = index;
      const topFlapVisible = getElementCache(flap, '.top-flap-visible');

      if (currentChars[index] === targetWord[index]) {
        completedFlapsRef.current += 1;
        if (completedFlapsRef.current === width) moveToNextWord();
        return;
      }

      let symbolCursor = characterSet.indexOf(currentChars[index]);
      if (symbolCursor === -1) symbolCursor = 0;

      const handleAnimationEnd = (e) => {
        if (e.target !== topFlapVisible) return;

        symbolCursor = (symbolCursor + 1) % characterSet.length;
        const currentChar = characterSet[symbolCursor];
        const nextChar = characterSet[(symbolCursor + 1) % characterSet.length];

        const updates = [{
          index,
          topVisible: currentChar,
          topQueued: nextChar,
          bottomVisible: currentChar,
          bottomQueued: nextChar
        }];

        batchUpdateFlaps(updates);

        if (currentChar !== targetWord[index]) {
          startFlapAnimation(index);
        } else {
          const topFlapVisible = getElementCache(flap, '.top-flap-visible');
          const bottomFlapQueued = getElementCache(flap, '.bottom-flap-queued');
          topFlapVisible.classList.remove('top-flap-animation');
          bottomFlapQueued.classList.remove('bottom-flap-animation');
          completedFlapsRef.current += 1;
          if (completedFlapsRef.current === width) moveToNextWord();
        }
      };

      // Attach persistent listener
      topFlapVisible.addEventListener('animationend', handleAnimationEnd);
      animationRef.current[index] = { listener: handleAnimationEnd, element: flap };

      // Initial update
      const updates = [{
        index,
        topVisible: characterSet[symbolCursor],
        topQueued: characterSet[(symbolCursor + 1) % characterSet.length],
        bottomVisible: currentChars[index],
        bottomQueued: characterSet[(symbolCursor + 1) % characterSet.length]
      }];
      batchUpdateFlaps(updates);

      // Start animation with staggered delay
      setTimeout(() => startFlapAnimation(index), index * 100);
    });

    // Cleanup on unmount or re-render
    return () => {
      flapElements.forEach((flap, index) => {
        const topFlapVisible = getElementCache(flap, '.top-flap-visible');
        if (animationRef.current[index]?.listener) {
          topFlapVisible.removeEventListener('animationend', animationRef.current[index].listener);
        }
      });
      cleanup();
    };
  }, [currentWordIndex, isInitialized, width, WORDS, characterSet]);

  // Set isInitialized after first render
  useEffect(() => {
    if (!isInitialized) {
      setIsInitialized(true);
    }
  }, [isInitialized]);

  // Initial render with static characters
  const initialChars = initialWord.padEnd(width, ' ').split('');

  return (
    <div className="split-flap-display" ref={flapRef}>
      {initialChars.map((char, index) => (
        <Flap key={index} char={char} />
      ))}
      {/* <NoiseFilter /> */}
    </div>
  );
};

Flap.propTypes = {
  char: PropTypes.string
};

SplitFlapDisplay.propTypes = {
  word: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  width: PropTypes.number,
  type: PropTypes.oneOf(['TIME', 'FROM', 'FLIGHT', 'REMARKS'])
};

export default SplitFlapDisplay;