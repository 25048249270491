import React, { useState, useEffect, useRef } from 'react';
import '../Styles/splitflap.css';
import SplitFlapDisplay from '../Components/SplitFlapDisplay';
import LedScroller from '../Components/LedScroller';
import { Link } from 'react-router-dom';

const COLUMN_CONFIG = {
  TIME: { width: 5, key: 'time' },
  FROM: { width: 5, key: 'from' },
  FLIGHT: { width: 6, key: 'flight' },
  REMARKS: { width: 10, key: 'remarks' }
};

const BoardHeader = () => (
  <div className="board-headers">
    {Object.keys(COLUMN_CONFIG).map(header => (
      <div key={header} className="header-item">{header}</div>
    ))}
  </div>
);

const splitText = (text, width, isRemarks = false) => {
  if (!isRemarks) {
    // For non-remarks columns, just return a single line
    return [text.padEnd(width), ''];
  }

  // For remarks, split into two lines as before
  const words = text.split(' ');
  let firstLine = '';
  let secondLine = '';

  for (const word of words) {
    if (!firstLine || (firstLine.length + 1 + word.length) <= width) {
      firstLine = firstLine ? `${firstLine} ${word}` : word;
    }
    else if (!secondLine || (secondLine.length + 1 + word.length) <= width) {
      secondLine = secondLine ? `${secondLine} ${word}` : word;
    }
    else {
      break;
    }
  }

  firstLine = firstLine.padEnd(width);
  secondLine = secondLine.padEnd(width);

  return [firstLine, secondLine];
};

const BoardRow = ({ data }) => {
  // Function to determine the link based on the remarks
  const getProductLink = (remarks) => {
    const words = remarks.toLowerCase().split(' ');
    let color = '';
    let baseUrl = '/';

    // Extract color and product type from remarks
    if (words.includes('pullover')) {
      baseUrl = '/como-pullover';
      color = words.includes('limoncello') ? 'Limoncello' : 'Campari';
    } else if (words.includes('hoodie')) {
      baseUrl = '/avery-hoodie';
      color = 'Kyoho';
    } else if (words.includes('tshirt')) {
      baseUrl = '/shion-tshirt';
      color = words.includes('cream') ? 'Cream' : 'Charcoal';
    } else if (words.includes('black') || words.includes('beige')) {
      baseUrl = '/lyon-pants';
      color = words.includes('black') ? 'Black' : 'Beige';
    }

    return `${baseUrl}?color=${color}`;
  };

  return (
    <Link 
      to={getProductLink(data.remarks)}
      className="board-row"
      style={{ textDecoration: 'none', cursor: 'pointer' }}
    >
      {Object.entries(COLUMN_CONFIG).map(([header, config]) => (
        <div key={header} className="row-item">
          <div className={`display-stack ${header === 'REMARKS' ? 'double-height' : 'single-height'}`}>
            {(() => {
              const [firstLine, secondLine] = splitText(data[config.key], config.width, header === 'REMARKS');
              return (
                <>
                  <SplitFlapDisplay 
                    word={[firstLine]} 
                    width={config.width}
                    type={header}
                  />
                  {header === 'REMARKS' && (
                    <SplitFlapDisplay 
                      word={[secondLine || ' '.repeat(config.width)]} 
                      width={config.width}
                      type={header}
                    />
                  )}
                </>
              );
            })()}
          </div>
        </div>
      ))}
    </Link>
  );
};

const useResponsiveSpeed = () => {
  const [speed, setSpeed] = useState(1);

  useEffect(() => {
    const calculateSpeed = () => {
      const width = window.innerWidth;
      if (width < 576) { // Mobile
        return 2;
      } else if (width < 768) { // Tablet
        return 1.5;
      } else if (width < 992) { // Small laptop
        return 2;
      } else if (width < 1200) { // Large laptop
        return 2.5;
      } else { // Extra large screens
        return 3;
      }
    };

    const handleResize = () => {
      setSpeed(calculateSpeed());
    };

    // Initial calculation
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return speed;
};

const PRODUCT_TYPES = {
  'COMO': ['PULLOVER'],
  'KYOHO': ['HOODIE'],
  'SHION': ['TSHIRT'],
  'LYON': ['PANTS']
};

const DepartureBoard = () => {
    // Generate current time in HH:MM format
    const getCurrentTime = () => {
      const now = new Date();
      return now.toLocaleTimeString('en-US', { 
        hour: '2-digit', 
        minute: '2-digit', 
        hour12: false 
      });
    };

    const currentTime = getCurrentTime();
    const containerRef = useRef(null);

    // Handle mobile viewport adjustments
    useEffect(() => {
      const adjustHeight = () => {
        if (!containerRef.current) return;
        
        // Set the container height to the actual visible area
        const vh = window.innerHeight;
        containerRef.current.style.height = `${vh - 60}px`;

        document.documentElement.style.zoom = "100%";
        document.body.style.zoom = "100%";
      };
      
      // Initial adjustment
      adjustHeight();
      
      // Adjust on resize and orientation change
      window.addEventListener('resize', adjustHeight);
      window.addEventListener('orientationchange', adjustHeight);
      
      // Adjust when scrolling starts/stops (handles URL bar showing/hiding)
      let scrollTimeout;
      window.addEventListener('scroll', () => {
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(adjustHeight, 100);
      });
      
      return () => {
        window.removeEventListener('resize', adjustHeight);
        window.removeEventListener('orientationchange', adjustHeight);
        window.removeEventListener('scroll', () => {
          clearTimeout(scrollTimeout);
        });
      };
    }, []);

  const [boardData, setBoardData] = useState({
    'row1': {
      time: currentTime,
      from: "COMO",
      flight: "IL1100",
      remarks: "LIMONCELLO PULLOVER"
    },
    'row2': {
      time: currentTime,
      from: "SHION",
      flight: "IL1101",
      remarks: "CREAM TSHIRT"
    },
    'row3': {
      time: currentTime,
      from: "AVERY",
      flight: "IL1102",
      remarks: "KYOHO HOODIE"
    },
    'row4': {
      time: currentTime,
      from: "LYON",
      flight: "IL1103",
      remarks: "LIMONCELLO PULLOVER"
    },
    'row5': {
      time: currentTime,
      from: "SHION",
      flight: "IL1104",
      remarks: "CREAM TSHIRT"
    },
    'row6': {
      time: currentTime,
      from: "AVERY",
      flight: "IL1105",
      remarks: "KYOHO HOODIE"
    }
  });

  // New state for order management
  const [displayedOrders, setDisplayedOrders] = useState([]);
  const [lastFetchedOrders, setLastFetchedOrders] = useState([]);
  const [occurrenceDict, setOccurrenceDict] = useState({
    'COMO': 0,
    'LYON': 0,
    'SHION': 0,
    'KYOHO': 0
  });

  // Product Coverage Check Function
  const ensureProductCoverage = (allOrders, currentOrders) => {
    // Get current product occurrences
    const occurrences = {
      'COMO': 0,
      'LYON': 0,
      'SHION': 0,
      'KYOHO': 0
    };
    

    // go through the currently displayed orders and count the occurrences of each product type
    currentOrders.forEach(order => {
      const productType = order.productName;
      if (productType) {
        occurrences[productType]++;
      }
    });
    
    // Find missing products
    const missingProducts = Object.entries(occurrences)
      .filter(([_, count]) => count === 0)
      .map(([type]) => type);
    
    if (missingProducts.length === 0) {
      return currentOrders;
    }

    // Find orders with missing products
    const updatedOrders = [...currentOrders];
    let remainingOrders = allOrders.slice(6); // Orders not in initial display

    missingProducts.forEach(missingType => {
      // Find first order with missing product type
      const replacementOrderIndex = remainingOrders.findIndex(
        order => order.productName === missingType
      );

      if (replacementOrderIndex !== -1) {
        // Find order to replace (bottom-up)
        for (let i = updatedOrders.length - 1; i >= 0; i--) {
          const currentType = updatedOrders[i].productName;
          if (occurrences[currentType] > 1) {
            // Replace order
            const replacementOrder = remainingOrders[replacementOrderIndex];
            updatedOrders[i] = replacementOrder;
            occurrences[currentType]--;
            occurrences[missingType]++;
            remainingOrders = remainingOrders.filter((_, index) => index !== replacementOrderIndex);
            break;
          }
        }
      }
    });

    return updatedOrders;
  };

  // Order Replacement Function
  const replaceOrder = (oldOrder, newOrder) => {
    // Get current occurrences
    const currentOccurrences = { ...occurrenceDict };
    
    // Check if replacement would break product coverage
    if (currentOccurrences[oldOrder.productName] <= 1) {
      return false;
    }

    // Update occurrences
    currentOccurrences[oldOrder.productName]--;
    currentOccurrences[newOrder.productName]++;

    return true;
  };

  // Helper function to get product type from product name
  const getDisplayType = (productName) => {
    const SKU = productName.split(' ')[0]; // Get the SKU name (COMO, KYOHO, etc)
    return PRODUCT_TYPES[SKU]?.[0] || productName; // Return the type or fallback to full name
  };

  // Initialize board with first 6 orders
  const initializeBoard = async () => {
    try {
      // 1. Fetch first 50 orders
      const apiUrl = process.env.NODE_ENV === 'development' 
        ? 'http://localhost:5000/api/orders'
        : '/api/orders';
      
      const response = await fetch(apiUrl);
      if (!response.ok) throw new Error('Failed to fetch orders');
      const orders = await response.json();
      
      // 2. Take first 6 for initial display
      let initialOrders = orders.slice(0, 6);

      // 3. Ensure product coverage
      initialOrders = ensureProductCoverage(orders, initialOrders);

      // 4. Update occurrenceDict for final orders
      const initialOccurrences = {
        'COMO': 0,
        'LYON': 0,
        'SHION': 0,
        'KYOHO': 0
      };
      
      initialOrders.forEach(order => {
        const productType = order.productName;
        if (productType) {
          initialOccurrences[productType]++;
        }
      });
      
      // 5. Format orders for display
      const formattedOrders = initialOrders.map(order => {
        const displayType = getDisplayType(order.productName);
        const orderDate = new Date(order.creationDate);


        return {
          time: orderDate.toLocaleTimeString('en-US', { 
            hour: '2-digit', 
            minute: '2-digit', 
            hour12: false 
          }),
          from: order.productName.substring(0, 5),
          flight: order.orderNumber || `OR${Math.random().toString(36).substr(2, 6)}`,
          remarks: `${order.color} ${displayType}`.toUpperCase(),
          itemId: order.itemId,
          productName: order.productName
        };
      });

      // Update occurrence dictionary state
      setOccurrenceDict(initialOccurrences);
      setLastFetchedOrders(orders.slice(0, 20));
      setDisplayedOrders(formattedOrders);
      
      // Sequentially update the board data with delays
      const updateBoardWithDelay = (index) => {
        if (index >= formattedOrders.length) return; // Stop when all orders are displayed
        
        const order = formattedOrders[index];
        const rowKey = `row${index + 1}`;
        
        // Update a single row at a time
        setBoardData(prevBoardData => ({
          ...prevBoardData,
          [rowKey]: {
            time: order.time,
            from: order.from,
            flight: order.flight,
            remarks: order.remarks
          }
        }));
        
        // Schedule the next update after delay (if there are more orders)
        if (index + 1 < formattedOrders.length) {
          setTimeout(() => updateBoardWithDelay(index + 1), 5000); // Delay between rows updating 
        }
      };
      
      // Start the sequential updates with the first order
      updateBoardWithDelay(0);

    } catch (error) {
      console.error('Error initializing board:', error);
    }
  };

  // Initialize board when component mounts
  useEffect(() => {
    initializeBoard();
  }, []);

  // Check for new orders function
  const checkForNewOrders = async () => {
    try {
      // 1. Fetch latest orders
      const apiUrl = process.env.NODE_ENV === 'development' 
        ? 'http://localhost:5000/api/orders'
        : '/api/orders';
      
      const response = await fetch(apiUrl);
      if (!response.ok) throw new Error('Failed to fetch orders');
      const latestOrdersAll = await response.json();
      const latestOrders = latestOrdersAll.slice(0, 20);
      
      // 2. Compare with lastFetchedOrders using itemId
      const lastFetchedIds = lastFetchedOrders.map(order => order.itemId);
      const newOrders = latestOrders.filter(order => !lastFetchedIds.includes(order.itemId));
      
      if (newOrders.length === 0) return [];

      // Update lastFetchedOrders with the new fetch
      setLastFetchedOrders(latestOrders);

      // 3. Filter new orders that can be displayed
      const displayableOrders = newOrders.filter(newOrder => {
        const SKU = newOrder.productName.split(' ')[0];
        // Check if we can replace an order of the same type
        return occurrenceDict[SKU] > 0;
      });

      return displayableOrders;
    } catch (error) {
      console.error('Error checking for new orders:', error);
      return [];
    }
  };

  // Set up polling for new orders
  useEffect(() => {
    const pollInterval = setInterval(async () => {
      const newOrders = await checkForNewOrders();
      
      if (newOrders.length > 0) {
        // Format the new order for display
        const newOrder = newOrders[0]; // Take the first new order
        const displayType = getDisplayType(newOrder.productName);
        const orderDate = new Date(newOrder.creationDate);
        
        const formattedNewOrder = {
          time: orderDate.toLocaleTimeString('en-US', { 
            hour: '2-digit', 
            minute: '2-digit', 
            hour12: false 
          }),
          from: newOrder.productName.substring(0, 5),
          flight: newOrder.orderNumber || `OR${Math.random().toString(36).substr(2, 6)}`,
          remarks: `${newOrder.color} ${displayType}`.toUpperCase(),
          itemId: newOrder.itemId,
          productName: newOrder.productName
        };

        // Find an order to replace (bottom-up)
        const SKU = newOrder.productName.split(' ')[0];
        const updatedOrders = [...displayedOrders];
        let replaced = false;
        let replacedOrderSKU = null;

        for (let i = updatedOrders.length - 1; i >= 0; i--) {
          const currentOrder = updatedOrders[i];
          const currentSKU = currentOrder.productName.split(' ')[0];
          
          if (currentSKU === SKU && occurrenceDict[currentSKU] > 0) {
            updatedOrders[i] = formattedNewOrder;
            replaced = true;
            replacedOrderSKU = currentSKU;
            break;
          }
          else if (occurrenceDict[currentSKU] >1) {
            updatedOrders[i] = formattedNewOrder;
            replaced = true;
            replacedOrderSKU = currentSKU;
            break;
          }
        }

        if (replaced) {
          // Update occurrenceDict to reflect the change
          const updatedOccurrences = { ...occurrenceDict };
          
          // If replacing an order with a different SKU
          if (replacedOrderSKU !== SKU) {
            // Decrement the count for the replaced SKU
            updatedOccurrences[replacedOrderSKU] = (updatedOccurrences[replacedOrderSKU] || 0) - 1;
            // Increment the count for the new SKU
            updatedOccurrences[SKU] = (updatedOccurrences[SKU] || 0) + 1;
          }
          
          // Update states
          setDisplayedOrders(updatedOrders);
          
          // Update boardData
          const updatedBoardData = { ...boardData };
          updatedOrders.forEach((order, index) => {
            const rowKey = `row${index + 1}`;
            updatedBoardData[rowKey] = {
              time: order.time,
              from: order.from,
              flight: order.flight,
              remarks: order.remarks
            };
          });
          setBoardData(updatedBoardData);
        }
      }
    }, 5000); // Poll every 5 seconds

    // Cleanup
    return () => clearInterval(pollInterval);
  }, [displayedOrders, occurrenceDict]); // Dependencies for the polling effect

  const scrollSpeed = useResponsiveSpeed();

  return (
    <div className="full-width-container" ref={containerRef}>
      <div className="departure-board">
        <div className="board-content-wrapper">
          <div className="board-content">
            <h1 className="board-title">Départs &nbsp; Departures</h1>
            <BoardHeader />
            <BoardRow data={boardData['row1']} />
            <BoardRow data={boardData['row2']} />
            <BoardRow data={boardData['row3']} />
            <BoardRow data={boardData['row4']} />
            <BoardRow data={boardData['row5']} />
            <BoardRow data={boardData['row6']} />
            <LedScroller 
              text="WELCOME TO IN LIEU AIRPORT — NEW ARRIVALS AVAILABLE"
              speed={scrollSpeed}
              color="#ff0000"
            />
            <h1 className="board-title-bottom">Avgångar &nbsp; 出発</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepartureBoard; 